import {callUrl} from "./api";

export default {
    async getClients(data = {}) {
        try {
            let res = await callUrl("GET", 'api/clients', data)
            return res['hydra:member'];
        } catch(e) {
            return [];
        }
    },

    async getClient(clientId) {
        try {
            let res = await callUrl("GET", `api/clients/${clientId}`)
            return res;
        } catch(e) {
            return null;
        }
    },

    async patchClient(clientId, data) {
        return await callUrl("PATCH", `api/clients/${clientId}`, data);
    },

    async deleteClient(clientId) {
        return await callUrl("DELETE", `api/clients/${clientId}`);
    },

    async addClient(data) {
        return await callUrl("POST", `api/clients`, data);
    }
}