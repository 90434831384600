import { computed, ref } from '@vue/composition-api'

export function computedFactory(expectedFields, target) {
    let refs = expectedFields.reduce((accumulator, field) => {
        return {
            ...accumulator,
            [`${field}_data`]: ref(this?.[target]?.[field] ?? null),
        }
    }, {})

    let computeds = expectedFields.reduce((accumulator, field) => {
        return {
            ...accumulator,
            [field]: computed({
                get() {return this[`${field}_data`] ?? this?.[target]?.[field] ?? null},
                set:(value) => {refs[`${field}_data`].value = value}
            })
        }
    }, {})

    return {...refs, ...computeds}
}


export function storeComputedFactory(expectedFields, store) {
    let refs = expectedFields.reduce((accumulator, field) => {
        return {
            ...accumulator,
            [`${field}_store`]: computed(() => store.getters[`data/get${field.charAt(0).toUpperCase() + field.slice(1)}`]),
        }
    }, {})

    let computeds = expectedFields.reduce((accumulator, field) => {
        return {
            ...accumulator,
            [field]: computed({
                get() {return this[`${field}_store`]},
                set:(value) => {store.commit(`data/set${field.charAt(0).toUpperCase() + field.slice(1)}`, value)}
            })
        }
    }, {})

    return {...refs, ...computeds}
}


export function emitterComputedFactory(expectedFields, target) {
    let refs = expectedFields.reduce((accumulator, field) => {
        return {
            ...accumulator,
            [`${field}_data`]: ref(this?.[target]?.[field] ?? null),
        }
    }, {})

    let computeds = expectedFields.reduce((accumulator, field) => {
        return {
            ...accumulator,
            [field]: computed({
                get() {return this[`${field}_data`] ?? this?.[target]?.[field] ?? null},
                set:(value) => {this.$emit("update", field, value)}
            })
        }
    }, {})

    return {...refs, ...computeds}
}


